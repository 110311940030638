import { render, staticRenderFns } from "./CampaignCard.vue?vue&type=template&id=40ffe939&scoped=true&"
import script from "./CampaignCard.vue?vue&type=script&lang=js&"
export * from "./CampaignCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40ffe939",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VBtn,VCardSubtitle,VDivider,VForm})
