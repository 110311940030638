<template>
    <div>
        <div v-if="description" class="d-block pa-2">
            <v-card-subtitle>{{ description }}</v-card-subtitle>
            <v-divider />
        </div>
        <v-form ref="form" v-model="valid">
            <v-jsf v-model="model" :schema="schema" :options="formOptions">
                <template slot="custom-rating-scale" slot-scope="context">
                    <rating-scale v-bind="context" />
                </template>
                <template slot="custom-sparkline" slot-scope="context">
                    <sparkline v-bind="context" />
                </template>
                <template slot="custom-springboard" slot-scope="context">
                    <springboard v-bind="context" :responseId="responseId" />
                </template>
                <template slot="custom-thumbs" slot-scope="context">
                    <thumbs v-bind="context" />
                </template>
                <template slot="custom-avatar" slot-scope="context">
                    <avatar v-bind="context" />
                </template>
            </v-jsf>
        </v-form>
        <template v-if="options.submittable">
            <v-btn
                :disabled="!valid"
                color="primary"
                class="text--white"
                block
                large
                dark
                @click="onSubmit"
            >
                Submit
            </v-btn>
        </template>
    </div>
</template>

<script>
export default {
    components: {
        RatingScale: () => import('./elements/RatingScale'),
        Sparkline: () => import('./elements/Sparkline'),
        Springboard: () => import('./elements/Springboard'),
        Thumbs: () => import('./elements/Thumbs'),
        Avatar: () => import('./elements/Avatar'),
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
        },
        schema: {
            type: Object,
            required: true,
        },
        values: {
            type: Object,
        },
        responseId: {
            type: String,
            required: true,
        },
        options: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    data() {
        return {
            valid: false,
            model: { ...this.values },
            formOptions: {
                // Cause validation to happen immediately on all fields.
                initialValidation: 'all',
                // Cause main field container to be a column, rather than wrapper row.
                objectContainerClass: 'flex-column',
                sectionClass: '',
                rules: {
                    isTrue(e) {
                        return !!e
                    },
                },
                timePickerProps: {
                    format: '24hr',
                },
            },
        }
    },
    methods: {
        onSubmit() {
            console.debug('submit')
            this.$emit('submit', this.model)
        },
    },
}
</script>

<style scoped></style>
